import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Header from '../components/Header'
import Ad from '../components/Ad'

const photoTemplate = ({ pageContext, data }) => {
  const { pageContent, links } = pageContext
  const [photo] = pageContent
  return (
    <>
      <Header />
      <div className={'with-sidebar'}>
        <div>
          <Ad minHeight={'125px'} maxWidth={'950px'} />
        </div>
        <div>
          <div className={'not-sidebar'}>
            <div>
              <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt={photo.description} />

              {pageContent.map((data, index) => {
                return <p key={`content_item_${index}`}>{photo.description}</p>
              })}
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '12px' }}>
                {links.map((item, index) => {
                  return (
                    <Link to={item.to} className={'next-link'} key={`link-${index}`}>
                      <button key={`button_${index}`}>{item.desc}</button>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={'sidebar'}>
            <Ad minHeight={'250px'} maxWidth={'300px'} />
            <br />
            <br />
            <Ad minHeight={'250px'} maxWidth={'300px'} />
          </div>
        </div>
        <div>
          <Ad minHeight={'125px'} maxWidth={'950px'} />
        </div>
      </div>
    </>
  )
}
export default photoTemplate

export const query = graphql`
  query CatImages($image: String) {
    file(relativePath: { eq: $image }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
